export default {
  mounted() {
    window.addEventListener('keyup', this.handleEscKey);
  },
  destroyed() {
    window.removeEventListener('keyup', this.handleEscKey);
  },
  methods: {
    handleEscKey(event) {
      if (event.keyCode === 27) this.onEsc();
    },
    onEsc() {},
  },
};
