const goliathImagePath = '/public';
const productImagePath = '/product-images';

const GOLIATH_PATH_PREFIX_REGEXP = RegExp(`^${goliathImagePath}`);

function replacePathPrefix(path) {
  if (!path) return undefined;
  return path.replace(GOLIATH_PATH_PREFIX_REGEXP, productImagePath);
}

function url(attachment) {
  if (!attachment) return undefined;
  if (attachment.remote) return attachment.url;
  return replacePathPrefix(attachment.path);
}

function thumbImageUrl(attachment) {
  if (!attachment) return undefined;
  if (attachment.remote) return attachment.url;
  return replacePathPrefix(attachment.thumbImagePath);
}

export { url as attachmentUrl, thumbImageUrl as attachmentThumbUrl };

export default { url, thumbImageUrl };
